<template>
	<div class="app-container">
		<div class="filter-container mt-3">
			<el-date-picker v-model="searchData.date_range" type="daterange" align="right" unlink-panels :range-separator="$t('general.to')" :start-placeholder="$t('general.date_start')" :end-placeholder="$t('general.date_end')" :picker-options="pickerOptions" class="filter-item" value-format="yyyy-MM-dd" :disabled="loading" @change="saveData()"></el-date-picker>
			
			<el-button class="filter-item" type="primary" icon="el-icon-search" @click="initial()" :loading="buttonloading">{{$t('button.search')}}</el-button>
			
			<el-button type="primary" :loading="buttonloading" class="filter-item float-right" v-b-modal.addRow v-if="permissionList.includes(permission.add)" icon="el-icon-plus">{{$t('button.add')}}</el-button>
		</div>
		
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="info" :loading="buttonloading" @click="infoRow(scope.row.id)" v-if="permissionList.includes(permission.info)" icon="el-icon-view">{{$t('button.info')}}</el-button>
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

        <b-modal id="addRow" :title="$t('menu.site_news_add')" @hide="clearDataList()" hide-footer size="lg" no-enforce-focus>
			<el-form @keyup.enter.native="addRow()" >
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="dataList.code" :placeholder="$t('admin_general.table_code')"></b-form-input>
				</b-form-group>
				
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_end_at')}}</template>
					<el-date-picker v-model="dataList.end_at" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :placeholder="$t('admin_general.table_end_at')" class="w-100"></el-date-picker>
				</b-form-group>
				
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_popup')}}</template>
					<el-switch v-model="dataList.is_popup" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_history')}}</template>
					<el-switch v-model="dataList.is_history" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
					<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				
				<el-tabs class="mb-3">
					<el-tab-pane v-for="item in languageList" :key="item.code" :item="item" :label="item.name">
						<el-form-item :label="$t('admin_general.table_title')" class="mb-1">
							<b-form-input v-model="dataList.title[item.id]" :disabled="loading"></b-form-input>
						</el-form-item>
						
						<el-form-item :label="$t('admin_general.table_content')" class="mb-1">
							<br/>
							<Tinymce ref="editor" v-model="dataList.content[item.id]" :height="400" :disabled="loading" class="mt-3"/>
						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>
		
		<b-modal id="infoRow" :title="$t('menu.site_news_info')" @hide="clearDataList()" hide-footer size="lg" no-enforce-focus>
			<p class="m-0 p-0"><b>{{thisDetail.title}}</b></p>
			
			<el-divider></el-divider>
			
			<p class="m-0 p-0 text-justify" v-html="thisDetail.content"></p>
			
			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('infoRow')">{{$t('button.cancel')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editRow" :title="$t('menu.site_news_edit')" @hide="clearDataList()" hide-footer size="lg" no-enforce-focus>
			<el-form @keyup.enter.native="editRow()" >
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="dataList.code" :placeholder="$t('admin_general.table_code')"></b-form-input>
				</b-form-group>
				
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_end_at')}}</template>
					<el-date-picker v-model="dataList.end_at" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :placeholder="$t('admin_general.table_dob')" class="w-100"></el-date-picker>
				</b-form-group>
				
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_popup')}}</template>
					<el-switch v-model="dataList.is_popup" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_history')}}</template>
					<el-switch v-model="dataList.is_history" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
					<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				
				<el-tabs class="mb-3">
					<el-tab-pane v-for="item in languageList" :key="item.code" :item="item" :label="item.name">
						<el-form-item :label="$t('admin_general.table_title')" class="mb-1">
							<b-form-input v-model="dataList.title[item.id]" :disabled="loading"></b-form-input>
						</el-form-item>
						
						<el-form-item :label="$t('admin_general.table_content')" class="mb-1">
							<br/>
							<Tinymce ref="editor" v-model="dataList.content[item.id]" :height="400" :disabled="loading" class="mt-3"/>
						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>
	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';
import Tinymce from '@/components/tinymce';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	date_range:'',
	created_at:'',
	end_at:'',
	title:'',
	sort:'',
	is_popup:'',
	status:''
}

export default{
	components: { pagination, backtotop, Tinymce },
	inject:['preloader','reload'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
				security:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData: Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "created_at",
                label: this.$t('admin_general.table_created_at'),
                width:'100'
			},{
                prop: "end_at",
                label: this.$t('admin_general.table_end_at'),
                width:'100'
			},{
                prop: "title",
                label: this.$t('admin_general.table_title'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "is_popup",
                label: this.$t('admin_general.table_popup'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			dataList:{
				code:'',
				end_at:'',
				sort:'',
				status:'',
				is_popup:0,
				is_history:0,
				title:[],
				content:[]
			},
			thisDetail: [],
			permission:{
                ajaxTable:'B25V8O',
                info:'T1QQUK',
                add:'J671RM',
                DBadd:'IOEQFW',
                edit:'MQY9TQ',
                DBedit:'J2K76X',
                delete:'VNV3IK',
                DBdelete:'PV8WSO'
            },
			permissionList:[],
			languageList:[],
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            }
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('site/news/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.code = '';
			this.dataList.end_at = '';
			this.dataList.sort = 10;
			this.dataList.status = '';
			this.dataList.is_history = 0;
			this.dataList.is_popup = 0;
			this.dataList.title = [];
			this.dataList.content = [];
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('site/news/DBadd',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					self.buttonloading = false;
					self.$bvModal.hide('addRow');
					self.initial();
					self.preloader(false);
				});
			}
		},infoRow(id){
			if(this.permissionList.includes(this.permission.info)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('site/news/info',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.thisDetail = data.thisDetail;
						var content = [];
						content = JSON.stringify(self.thisDetail.content);
						content = self.decoder(content);
						self.thisDetail.content = JSON.parse(content);
						self.$bvModal.show('infoRow');
					}
					self.buttonloading = false;
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('site/news/edit',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.dataList = data.thisDetail;
						var content = [];
						content = JSON.stringify(self.dataList.content);
						content = self.decoder(content);
						self.dataList.content = JSON.parse(content);
						self.$bvModal.show('editRow');
					}
					self.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('site/news/DBedit',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					self.buttonloading = false;
					self.$bvModal.hide('editRow');
					self.initial();
					self.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.delete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('site/news/DBdelete',this.postData);
					result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid){
						self.$message({
						type: 'success',
						message: data.returnMsg
						});
						
						self.initial();
					}else{
						self.errors = data.returnMsg;
						
						self.errors.forEach(function (value){
						text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self, text);
					}
					
					});
					
					this.buttonloading = false;

				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },decoder (str) {
			str = str.replace(/&quot;/g,"'");
			var textArea = document.createElement('textarea');
			textArea.innerHTML = str;

			return textArea.value;
		},saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.languageList = JSON.parse(getLocalStorage('languageList'));
		this.initial();
	}
}
</script>